import React from 'react'
import { Box } from '@chakra-ui/react'
import { ContentstackBannerSplit as ContentstackBannerSplitProps } from '@Types/contentstack'
import { customColors } from './utils'
import { BannerSplit } from '../cms-components/banner-split'
import { ObjectFit } from '../cms-components/types'
import { imageOptimization } from '../general/components/image-optimization'

export const ContentstackBannerSplit = ({
  placeholder_id,
  eyebrow,
  banner_title,
  banner_title_font_family,
  banner_title_font_size,
  content,
  inverted,
  inverted_mobile,
  image_desktop,
  image_mobile,
  cta_alpha_href,
  cta_alpha_label,
  cta_beta_label,
  cta_beta_href,
  overlay_image_desktop,
  overlay_image_mobile,
  proportion_layout,
  theme,
  background_color,
  heading_theme,
  heading_text_color,
  text_font_color,
  select_custom_text_color,
  background_font_color,
  overlay_image_height,
  image_desktop_fit,
  image_mobile_fit,
  analyticsTrackingData,
  isMobileDevice,
  image_href,
  container_size,
}: ContentstackBannerSplitProps) => {
  const select_custom_background_color = background_font_color === 'Custom' ? background_color : null
  const select_custom_title_font_color = heading_theme === 'Custom' ? heading_text_color : null
  const select_custom_text_color_selected = text_font_color === 'Custom' ? select_custom_text_color : null

  const background = customColors('background', theme, select_custom_background_color)
  const textColor = customColors('text', theme, select_custom_text_color_selected)
  const titleTextColor = customColors('title', theme, select_custom_title_font_color)

  return (
    <BannerSplit
      placeholder_id={placeholder_id}
      isMobileDevice={isMobileDevice}
      analyticsTrackingData={analyticsTrackingData}
      inverted={inverted ?? undefined}
      invertedMobile={inverted_mobile ?? undefined}
      image={{
        href: image_href,
        imageDesktop: {
          src: imageOptimization(image_desktop?.url),
          alt: image_desktop?.description || image_desktop?.title || banner_title,
          objectFit: (image_desktop_fit as ObjectFit) ?? 'cover',
        },
        imageMobile: {
          src: imageOptimization(image_mobile?.url),
          alt: image_mobile?.description || image_mobile?.title || banner_title,
          objectFit: (image_mobile_fit as ObjectFit) ?? 'cover',
        },
      }}
      overlayImage={{
        imageDesktop: {
          url: imageOptimization(overlay_image_desktop?.url),
          title: overlay_image_desktop?.description || overlay_image_desktop?.title || banner_title,
        },
        imageMobile: {
          url: overlay_image_mobile?.url,
          title: overlay_image_mobile?.description || overlay_image_mobile?.title || banner_title,
        },
        overlay_image_height,
      }}
      text={{
        eyebrow: {
          children: eyebrow,
        },
        title: {
          font: {
            family: banner_title_font_family,
            size: banner_title_font_size,
          },
          content: {
            children: banner_title,
          },
        },
        body: {
          children: content ? <Box dangerouslySetInnerHTML={{ __html: content }} /> : undefined,
        },
        ctaButtonPrimary: {
          children: cta_alpha_label,
          href: cta_alpha_href ?? '',
        },
        ctaButtonSecondary: {
          children: cta_beta_label,
          href: cta_beta_href ?? '',
        },
      }}
      layout={proportion_layout}
      theme={theme}
      backgroundColor={background}
      headingTextColor={titleTextColor}
      textColor={textColor}
      containerSize={container_size}
    />
  )
}
